import React, { useState, useEffect, memo, useCallback } from "react";
import { SearchOutlined, FileSearchOutlined, FilterOutlined, StarFilled, DeleteFilled, ArrowDownOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, List, Tag, Select, Table } from "antd/es";
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import { countImagesPerModel, deleteModel, downloadModel, findRunArgs, listRemoteModels, getWasabiFile, fetchResults, getWasabiFileURL } from "../api";

import CardTitle from "./CardTitle";
import { Checkbox } from "antd";
import { Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { recursiveObjectSort } from "../util";

import store from "../store";
import { ReloadOutlined } from "@ant-design/icons/es";
import CollapsingCard from "./CollapsingCard";
import { useMemo } from "react";

import '../style/model-storage.scss';

function pathSorter(a, b) {
    // 2024-04-30/02h40m12s/checkpoint-12000/unet
    const partsA = a.path.split('/');
    const partsB = b.path.split('/');

    const dateA = partsA[0];
    const dateB = partsB[0];

    if (dateA !== dateB) {
        return dateA.localeCompare(dateB);
    } else {
        const timeA = partsA[1];
        const timeB = partsB[1];

        if (timeA !== timeB) {
            return timeA.localeCompare(timeB);
        } else {
            const checkpointA = Number(partsA[2].substr(11));
            const checkpointB = Number(partsB[2].substr(11));

            return checkpointA - checkpointB;
        }
    }
}

const ModelStorageBrowser = ({ currentModel, setCurrentModel }) => {
    const [showFilters, setShowFilters] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [filters, setFilters] = useState({ query: '', localOnly: false, selecting: true });
    const [selectedModel, setSelectedModel] = useState({ open: false, model: null });
    const [loading, setLoading] = useState(false);

    const remoteModels = store.models.useRemoteModels();
    const setRemoteModels = store.models.useSetRemoteModels();
    const diskUsage = store.models.useDiskUsage();


    const updateModels = useCallback(() => {
        setLoading(true);
        listRemoteModels()
            .then(models => models.filter(m => m.path.split('/').length > 4).map(model => {
                const [date, time, checkpoint] = model.path.split('/');
                const path = `20${date}/${time}/${checkpoint}/unet`;

                return {
                    ...model,
                    path,
                    fullPath: model.path,
                    step: Number(checkpoint.split("checkpoint-")[1]) | 0
                };
            })).then(models => {
                models.sort((a, b) => pathSorter(b, a));
                return models;
            }).then(setRemoteModels).then(() => setLoading(false));
    }, [setRemoteModels, setLoading]);

    useEffect(() => {
        updateModels();
    }, [updateModels]);

    const totalSize = remoteModels.map(m => m.size).reduce((a, b) => a + b, 0);
    const totalSizeGb = (totalSize / 1024 / 1024 / 1024).toFixed(0);

    const bodyStyle = { padding: '0 !important' };

    if (collapsed) {
        bodyStyle.display = 'none';
    }

    const [size, diskUsed, diskFree] = diskUsage || [0, 0, 0];
    const free = (diskFree / 1024 / 1024 / 1024).toFixed(0);

    const title = <CardTitle icon={<FileSearchOutlined />} collapsed={collapsed} setCollapsed={setCollapsed}>
        Model Storage
        <Tag color="success" size={"small"} style={{ marginLeft: 4, padding: '0 4px', fontSize: 11 }}>{totalSizeGb} GB</Tag>
        <Tag color="info" size={"small"} style={{ marginLeft: 4, padding: '0 4px', fontSize: 11 }}>{free} GB free</Tag>
        <Button size="small" onClick={e => { updateModels(); e.preventDefault(); e.stopPropagation(); }} disabled={loading} loading={loading} icon={<ReloadOutlined style={{ fontSize: 12 }} />} />
    </CardTitle>;

    const clearSelection = store.models.useClearSelection();

    return (
        <Card title={title} className="run-container settings-card model-storage" style={{ height: '100%' }} bodyStyle={bodyStyle}>
            {!collapsed && <Row>
                <Col span={24}>
                    <Input className="model-search-field"
                        placeholder="Search..."
                        value={filters.query}
                        onChange={e => setFilters(f => ({ ...f, query: e.target.value }))}
                        prefix={<SearchOutlined style={{ padding: '0 0' }} />}
                        addonAfter={<Button style={{ height: 'auto', width: 'auto' }} onClick={() => setShowFilters(v => !v)} type={showFilters ? "primary" : "default"} icon={<FilterOutlined style={{ padding: '0 8px' }} />} />}
                    />
                </Col>

                {showFilters && <Col span={24}>
                    <ModelFilters {...{ filters, setFilters, clearSelection }} />
                </Col>}

                <Col span={24} style={{ overflow: 'hidden' }}>
                    <div className={"model-list-wrapper" + (selectedModel.open ? " slide" : "")}>
                        <ModelList {...{ remoteModels, filters, currentModel, setCurrentModel }} onClick={m => setSelectedModel(v => ({ ...v, model: m, open: true }))} />

                        <div className="off-canvas">
                            {selectedModel.model && <ModelDetails {...{ selectedModel: selectedModel.model }} close={() => setSelectedModel(v => ({ ...v, open: false }))} />}
                        </div>
                    </div>
                </Col>
            </Row>}
        </Card>
    )
};
// PaginationConfig
const paginationConfig = {
    position: 'bottom',
    simple: true,
    align: 'center',
    size: 'small',
    showQuickJumper: false,
    totalBoundaryShowSizeChanger: 50000,
    pageSize: 20
};

const ModelList = ({ remoteModels, filters, onClick, currentModel, setCurrentModel }) => {
    const modelLookup = store.models.useModelLookup();

    const { localOnly, selecting, query } = filters;
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        const filter = () => {
            const filtered = [...remoteModels]
                .filter(m => query ? m.path.includes(query) : true)
                .map(m => ({ ...m, local: modelLookup[m.path] }));

            setFiltered(localOnly ? filtered.filter(m => m.local) : filtered);
        };

        filter();
    }, [remoteModels, modelLookup, localOnly, query]);

    const [imageCounts, setImageCounts] = useState({});

    useEffect(() => {
        const updateCounts = () => {
            countImagesPerModel().then(setImageCounts);
        };

        updateCounts();

        const key = setInterval(() => updateCounts(), 30000);
        return () => clearInterval(key);
    }, []);

    return (
        <List
            pagination={paginationConfig}
            size={"small"}
            rowKey="path"
            loading={remoteModels.length === 0}
            dataSource={filtered}
            renderItem={(d, idx) => <ModelListItem
                data={d}
                imageCounts={imageCounts}
                key={idx}
                idx={idx}
                {...{ onClick, currentModel, setCurrentModel, selecting }}
            />}
            className="model-list"
        />
    )
};

const ModelDetails = (props) => {
    const { selectedModel, close } = props;
    const [modelArgs, setModelArgs] = useState(null);
    const [captions, setCaptions] = useState(null);
    const [lossFile, setLossFile] = useState(null);

    const path = selectedModel.path.split("/checkpoint-")[0];
    const step = Number(selectedModel.path.split("checkpoint-")[1].split("/")[0]);

    useEffect(() => {
        if (selectedModel) {
            findRunArgs("projects/pixels-randomized-coa2/" + path, 1).then(args => {
                setModelArgs(recursiveObjectSort(args));

                getWasabiFile(`models/coa-finetune/${path.substr(2)}/captions.txt`).then(captions => {
                    setCaptions(captions);
                });

                getWasabiFile(`models/coa-finetune/${path.substr(2)}/image-loss.txt`).then(lossFile => {
                    setLossFile(lossFile);
                });
            });
        }
    }, [selectedModel, path, step]);

    const { losses, relative, overall, averages, timestepFrequencies } = useMemo(() => {
        if (!lossFile) {
            return { losses: null, relative: null, overall: null, averages: null, timestepFrequencies: null };
        }

        const lines = lossFile.split('\n').map(l => l.trim()).filter(l => l.length);
        const data = analyzeLosses(lines);

        return data;
    }, [lossFile]);


    const files = useMemo(() => Object.keys(losses ?? []).sort((a, b) => relative[b] - relative[a]), [losses, relative]);
    const imageNames = useMemo(() => (files ?? []).toSorted().map(f => ({
        label: f.split('/').at(-1),
        value: f,
    })), [files]);

    return (
        <div className="off-canvas-content">
            <div className="off-canvas-header">
                <Button type="default" size="small" icon={<ArrowLeftOutlined />} onClick={() => close()} />
                <div>{path} <Tag>{step}</Tag></div>
            </div>

            <div className="off-canvas-body" style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <CollapsingCard title="Model details">
                    <pre>{selectedModel ? JSON.stringify(selectedModel, null, 4) : '...'}</pre>
                </CollapsingCard>

                <CollapsingCard title="Invocation args" defaultCollapsed>
                    <pre>{modelArgs ? JSON.stringify(modelArgs, null, 4) : '...'}</pre>
                </CollapsingCard>

                <CollapsingCard title="Loss per timestep" defaultCollapsed>
                    {lossFile && <OverallLossPerTimestep overall={overall} losses={losses} />}
                </CollapsingCard>

                <CollapsingCard title="Timestep sampling distribution" defaultCollapsed>
                    {lossFile && <TimestepSamplingDistribution frequencies={timestepFrequencies ?? []} averages={averages} losses={losses} overall={overall} />}
                </CollapsingCard>

                <CollapsingCard title="Per-image (normalized) training loss" defaultCollapsed>
                    {lossFile && <NormalizedImageLosses losses={losses} relative={relative} overall={overall} files={files} imageNames={imageNames} averages={averages} />}
                </CollapsingCard>

                <CollapsingCard title="Per-image loss analysis" defaultCollapsed>
                    {lossFile && <ImageLosses losses={losses} relative={relative} overall={overall} files={files} imageNames={imageNames} />}
                </CollapsingCard>

                <CollapsingCard title="Captions" defaultCollapsed>
                    {modelArgs ? <>
                        Prefix:
                        <pre>{modelArgs.current_status.args.caption_prefix ?? <i>None</i>}</pre>
                        Suffix:
                        <pre>{modelArgs.current_status.args.caption_suffix ?? <i>None</i>}</pre>
                    </> : null}

                    Captions file:
                    <pre>{captions ?? '...'}</pre>
                </CollapsingCard>
            </div>
        </div>
    )
}

const NormalizedImageLosses = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const { imageNames, losses, averages } = props;


    return (<>
        <ImageSelector bind={[selectedFile, setSelectedFile]} imageNames={imageNames} />

        {selectedFile && (<>
            {
                losses[selectedFile] ? <div>
                    <Table
                        style={{ marginTop: 12 }}
                        rowKey={"step"}
                        size="small"
                        dataSource={losses[selectedFile]}
                        columns={[
                            { title: "Training step", dataIndex: "step" },
                            { title: "Timestep", dataIndex: "timestep", sorter: (a, b) => a.timestep - b.timestep },
                            { title: "Loss", dataIndex: "loss", sorter: (a, b) => a.loss - b.loss, render: v => v.toFixed(5) },
                            { title: "Step-relative", sorter: (a, b) => a.loss - b.loss, render: (_, v) => (v.loss / (averages[v.timestep]?.avg ?? 1.0)).toFixed(5) }
                        ]}
                        pagination={{ position: ["bottomCenter"], responsive: true, size: "small" }} />
                </div> :
                    <>No entry</>
            }
        </>)}
    </>);
}


const TimestepSamplingDistribution = (props) => {
    const { frequencies, averages, overall } = props;

    const chart = useMemo(() => frequencies.map((v, idx) => ({ x: idx, y: v, loss: overall[idx] })), [frequencies]);
    const line = [{ id: "incidence", data: chart }];

    return (<div>
        <div style={{ width: '100%', minHeight: 200, height: 200 }}>
            <ResponsiveLine
                data={line}
                // keys={showBar ? barKeys : undefined}
                // indexBy={showBar ? "x" : undefined}
                margin={{ top: 10, right: 50, bottom: 30, left: 10 }}
                xScale={{ type: 'linear', }}
                yScale={{ type: 'linear' }}
                yFormat={v => `${v}`}
                enableSlices="x"
                curve="linear"
                axisTop={null}
                axisLeft={null}
                animate={false}
                axisRight={{
                    format: '.6f',
                    legendOffset: 0
                }}
                enableGridX={true}
                colors={{ scheme: 'tableau10' }}
                theme={{
                    "tooltip": {
                        "container": {
                            "color": "black"
                        }
                    },
                    "grid": {
                        "line": {
                            "stroke": "#777777"
                        }
                    },
                    "axis": {
                        "domain": {
                            "line": {
                                "stroke": "#777777",
                                "strokeWidth": 1
                            }
                        },
                        // "grid": 6,
                        "legend": {
                            "text": {
                                "fontSize": 12,
                                "fill": "#ff0000",
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            }
                        },
                        "ticks": {
                            "text": {
                                "fontSize": 11,
                                "fill": "#c0c0c0",
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            }
                        }
                    },
                }}
                lineWidth={1}
                enablePoints={false}
                crosshairType="cross"
                enableTouchCrosshair={true}
                useMesh={false}
                legends={[]}
            />
        </div>

        <div>
            <Table
                rowKey={"x"}
                size="small"
                dataSource={chart}
                columns={[
                    { title: "Timestep", dataIndex: "x" },
                    { title: "Samples", dataIndex: "y", sorter: (a, b, order) => a.y - b.y },
                    { title: "Avg. loss", dataIndex: "loss", sorter: (a, b, order) => a.loss - b.loss, render: v => v.toFixed(5) }
                ]}
                pagination={{ position: ["bottomCenter"], responsive: true, size: "small" }} />
        </div>
    </div>);
}


const OverallLossPerTimestep = (props) => {
    const { overall = [] } = props;

    const chart = useMemo(() => Array.from({ length: 1000 }).map((_, idx) => ({ x: idx, y: overall[idx] == 0 ? null : overall[idx] })), [overall]);
    const bars = useMemo(() => Object.fromEntries(overall.map((e, idx) => ([`${idx}`, e]))), [overall]);
    // const barKeys = Object.keys(bars);

    const showBar = false;
    const ChartComponent = showBar ? ResponsiveBar : ResponsiveLine;
    const data = showBar ? [bars] : [{ id: "loss", data: chart }];

    return (<div>
        <div style={{ width: '100%', minHeight: 200, height: 200 }}>
            <ChartComponent
                data={data}
                // keys={showBar ? barKeys : undefined}
                // indexBy={showBar ? "x" : undefined}
                margin={{ top: 10, right: 50, bottom: 30, left: 10 }}
                xScale={{ type: 'linear', }}
                yScale={{ type: 'linear' }}
                yFormat=" >-.6f"
                enableSlices="x"
                curve="linear"
                axisTop={null}
                axisLeft={null}
                animate={false}
                axisRight={{
                    format: '.6f',
                    legendOffset: 0
                }}
                enableGridX={true}
                colors={{ scheme: 'tableau10' }}
                theme={{
                    "tooltip": {
                        "container": {
                            "color": "black"
                        }
                    },
                    "grid": {
                        "line": {
                            "stroke": "#777777"
                        }
                    },
                    "axis": {
                        "domain": {
                            "line": {
                                "stroke": "#777777",
                                "strokeWidth": 1
                            }
                        },
                        // "grid": 6,
                        "legend": {
                            "text": {
                                "fontSize": 12,
                                "fill": "#ff0000",
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            }
                        },
                        "ticks": {
                            "text": {
                                "fontSize": 11,
                                "fill": "#c0c0c0",
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            }
                        }
                    },
                }}
                lineWidth={1}
                enablePoints={false}
                crosshairType="cross"
                enableTouchCrosshair={true}
                useMesh={false}
                legends={[]}
            />
        </div>
    </div>);
}

const ImageLosses = (props) => {
    const { losses, relative, files, imageNames } = props;

    const [selectedFile, setSelectedFile] = useState(null);
    const [exportCaptions, setExportCaptions] = useState(false);


    return (<div>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
                <ImageSelector bind={[selectedFile, setSelectedFile]} imageNames={imageNames} />
            </div>

            <div style={{ flex: 1 }}>
                <Checkbox checked={exportCaptions} onChange={ev => setExportCaptions(ev.target.checked)}>Export sorted captions</Checkbox>
            </div>
        </div>

        {selectedFile && <>
            {JSON.stringify(losses[selectedFile])}
        </>}

        {exportCaptions && <div>
            <pre>{
                files.join('\n')
            }</pre>
        </div>}

        <table className="image-losses" style={{ marginTop: 8 }}>
            <thead>
                <th>Rel. avg. loss</th>
                <th>Image</th>
                <th>Samples</th>
            </thead>
            <tbody>
                {files.map(f => <tr key={f}>
                    <td className="monospaced loss-value">{relative[f].toFixed(5)}</td>
                    <td className="filename">{f.split('/').at(-1)}</td>
                    <td>{losses[f].length}</td>
                </tr>)}
            </tbody>
        </table>
    </div>);
}

const ImageSelector = (props) => {
    const { bind: [value, setValue], imageNames } = props;

    return (
        <Select
            value={value}
            placeholder={`Analyze specific dataset image... (${imageNames.length})`}
            options={imageNames}
            onChange={v => setValue(v)}
            virtual={true}
            allowClear
            style={{ width: '100%' }}
            showSearch
        />
    );
}

function analyzeLosses(lines) {
    const results = [];
    let hasNans = false;

    for (const line of lines) {
        const [file, losses] = line.split(": ");

        const steps = losses.split("), (").map(s => s.replace("(", "").replace(")", "").trim()).map(parseStep);
        for (const s of steps) {
            if (s.loss !== s.loss) {
                hasNans = true;
                s.loss = 0.0;
            }
        }

        results.push([file, steps]);
    }

    // Compute the averages each timestep
    const timestepLosses = Array.from({ length: 1000 }, () => []);
    const averages = Array.from({ length: 1000 }, () => ({ min: 0.0, max: 0.0, avg: 0.0 }));

    for (const [_, losses] of results) {
        for (const { timestep, loss } of losses) {
            timestepLosses[timestep].push(loss);
        }
    }

    const timestepFreq = timestepLosses.map(l => l.length);

    // Compute averages per timestep
    for (let i = 0; i < 1000; i++) {
        const losses = timestepLosses[i];
        const sum = losses.reduce((acc, v) => acc + v, 0);
        const min = losses.reduce((acc, v) => Math.min(acc, v), 0xFFFFFF);
        const max = losses.reduce((acc, v) => Math.max(acc, v), 0);

        averages[i].avg = losses.length ? (sum / losses.length) : 0.0;
        averages[i].max = losses.length ? max : 0.0;
        averages[i].min = losses.length ? min : 0.0;
    }

    const relativeAverages = {};

    // Compute losses per file relative to the average loss
    for (const [file, steps] of results) {
        const relative = steps.map(({ timestep, loss }) => loss / averages[timestep].avg);
        const relAvg = relative.reduce((acc, v) => acc + v, 0) / relative.length;

        relativeAverages[file] = relAvg;
    }

    const result = {
        losses: Object.fromEntries(results),
        relative: relativeAverages,
        overall: averages.map(avg => avg.avg),
        averages,
        timestepFrequencies: timestepFreq,
        hasNans,
    };

    console.log(result);
    return result;
}

function parseStep(s) {
    const values = s.split(",").map(s => parseFloat(s.trim()));
    if (values.length < 3) {
        return null;
    }

    const [step, timestep, loss, ..._rest] = values;
    return { step, timestep, loss };
}


const ModelListItem = memo(function ModelListItem({ data, imageCounts, onClick, currentModel, setCurrentModel, selecting }) {
    const { size, path, local, fullPath } = data;

    const modelKey = path.replace('/diffusion_pytorch_model.safetensors', '');
    const parts = path.split('/');
    const title = parts.slice(0, 2).join('/');
    const step = data.step;
    const numImages = imageCounts ? (imageCounts[modelKey] || 0) : 0;

    const [favourite, setFavourite] = useState(false);
    const [loading, setLoading] = useState(false);
    const [thumbnails, setThumbnails] = useState([]);

    const isSelected = store.models.use(s => s.selection.includes(modelKey));
    const select = store.models.use(s => s.select);
    const deselect = store.models.use(s => s.deselect);

    const filterImages = store.models.use(s => s.setImageFilter);

    const download = useCallback(() => {
        setLoading(true);
        console.log('Downloading', path);

        downloadModel(path.substr(2) + '/diffusion_pytorch_model.safetensors').then(() => setLoading(false));
    }, [path]);

    const deleteFiles = useCallback(() => {
        setLoading(true);
        console.log('Deleting', path);

        deleteModel(path.substr(2) + '/diffusion_pytorch_model.safetensors').then(() => setLoading(false));
    }, [path]);

    // const downloadLocal = useCallback(() => {
    //     const path2 = path.split("/checkpoint-")[0];
    //     const url = getWasabiFileURL("models/coa-finetune/" + path.substr(2) + '/diffusion_pytorch_model.safetensors');
    //     window.location.href = url;
    // }, [path]);

    const link = getWasabiFileURL(`models/coa-finetune/${fullPath}`);
    const isCurrent = modelKey === currentModel;

    const changeModel = e => {
        e.preventDefault();
        e.stopPropagation();

        setCurrentModel(modelKey);
    };

    // Download progress:
    // linear-gradient(90deg, #1a3411 50%, #00FFFF00 50%)

    const clickHandler = e => {
        const className = e.target.classList;

        if (className.contains('model-name') || className.contains('model-list-item')) {
            onClick(data);
        }
    }

    const setSelected = (ev) => ev.target.checked ? select(modelKey) : deselect(modelKey);

    return <List.Item className={"model-list-item " + (isCurrent ? "active" : "")} onClick={clickHandler}>
        <div className="favourite-model">
            {selecting
                ? (<>
                    <Checkbox checked={isSelected} onChange={setSelected} />
                </>)
                : <StarFilled onClick={() => setFavourite(v => !v)} className={favourite ? "active" : ""} />}
        </div>

        <span className='model-name'>{title}</span>
        <Tag color={"default"} style={{ marginRight: 0 }}>{step}</Tag>

        <span>
            {local && <Checkbox checked={isCurrent} onChange={changeModel} disabled={!local} style={{ marginLeft: 8 }} />}
        </span>

        {numImages ? <Button type="link" size="small" className="model-list-num-images" onClick={() => filterImages(modelKey)}>{numImages} images</Button> : null}

        <span style={{ flexGrow: 1 }}>
            {thumbnails.length}
        </span>

        {local ? (
            <Tooltip destroyTooltipOnHide title="Delete model from disk">
                <Button type="default" danger size="small" loading={loading}
                    onClick={e => { e.preventDefault(); e.stopPropagation(); deleteFiles() }}
                    icon={<DeleteFilled />}
                />
            </Tooltip>
        ) : (<>
            <Button
                type="default"
                size="small"
                loading={loading}
                onClick={e => { e.preventDefault(); e.stopPropagation(); download() }}
                icon={<ArrowDownOutlined />}
            />
            <Button
                href={link}
                type="default"
                size="small"
                loading={loading}
                // onClick={e => { e.preventDefault(); e.stopPropagation(); downloadLocal() }}
                icon={<LinkOutlined />}
            />

        </>)
        }

        <Tag color={"primary"} style={{ marginRight: 0 }}>
            {(size / 1024 / 1024 / 1024).toFixed(2)} GB
        </Tag>
    </List.Item>
}, isMemoEqual);

function isMemoEqual(p, n) {
    const eq = isMemoDataEqual(p.data, n.data) &&
        p.idx === n.idx &&
        p.model === n.model &&
        p.imageCounts === n.imageCounts &&
        p.currentModel === n.currentModel &&
        p.setCurrentModel === n.setCurrentModel &&
        p.selecting === n.selecting;

    return eq;
}

function isMemoDataEqual(prevData, nextData) {
    const { size: size1, path: path1, local: local1 } = prevData;
    const { size: size2, path: path2, local: local2 } = nextData;

    return size1 === size2 && path1 === path2 && local1 === local2;
}

const ModelFilters = (props) => {
    const { filters, setFilters, clearSelection } = props;

    const numSelected = store.models.use(s => s.selection.length);

    return (
        <div className="model-storage-filters">
            <Checkbox checked={filters.localOnly} onChange={e => setFilters(f => ({ ...f, localOnly: e.target.checked }))}>
                Only show local models
            </Checkbox>

            {numSelected ?
                <Button size="small" onClick={clearSelection}>Clear selection ({numSelected})</Button>
                :
                <Button size="small" disabled>Clear selection</Button>
            }
        </div>
    )
};


export default ModelStorageBrowser;
