import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import { LoadingOutlined, ToolFilled, SettingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Row, Image, Card, Modal, Button, notification, Space } from "antd/es";

import { ResponsiveLine } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';

import ArgsEditor from "./ArgumentEditor";
import ValidationImage from "./ValidationImage";

import { friendlyName, paramClass } from "../util";
import { useSettings } from "./RecentResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/sharp-light-svg-icons";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons/es";
import { fetchRunArgs, getArgumentChanges, getTrainingChart, putRunArgs } from "../api";

import "../style/args-history.scss";
import ReactTimeago from "react-timeago";
import { Checkbox } from "antd";

const { PreviewGroup } = Image;

function runContainerPropsAreEqual(prev, next) {
    const { data, status } = prev;
    const { data: data2, status: status2 } = next;

    return JSON.stringify(data) === JSON.stringify(data2) && JSON.stringify(status) === JSON.stringify(status2);
}

function useRefreshing(fetcher, intervalMillis = 10000, initialValue = null) {
    const [value, setValue] = useState(initialValue ?? null);

    useEffect(() => {
        const asyncFn = (async () => {
            const value = await fetcher();
            setValue(value);
        });

        const key = window.setInterval(() => {
            asyncFn();
        }, intervalMillis);

        asyncFn();
        return () => window.clearInterval(key);
    }, [intervalMillis]);

    return value;
}

const TrainingRun = memo(function TrainingRun(props) {
    const { data, modal, status, derive } = props;
    const { args, images, changes = [], run_id } = data;
    const argumentChanges = useRefreshing(() => getArgumentChanges(run_id));

    const [collapsed, setCollapsed] = useState(false);

    const changesAtSteps = argumentChanges?.map(c => c.modify_args?.step ?? -1) ?? [];
    const argChanges = changesAtSteps.map(step => images.find(img => img.step < step)?.step).filter(v => v);

    return (
        <Card className="run-container" title={<RunCardTitle {...{ status, args, images, changes, setCollapsed, derive }} />}>
            <PreviewGroup preview={{ toolbarRender: () => <></>, countRender: () => <></> }}>
                {!collapsed && (
                    <Row gutter={[0, 0]}>
                        {images.map(r => <ValidationImage key={r.run_id + r.step} data={r} modal={modal} changed={argChanges.includes(r.step)} />)}
                    </Row>
                )}
            </PreviewGroup>
        </Card>
    );
}, runContainerPropsAreEqual);

const RunConfig = (props) => {
    const { runId, statusRef } = props;

    const [active, setActive] = useState('args');
    const [chart, setChart] = useState([]);
    const [maCount, setMaCount] = useState(64);
    const [autorefresh, setAutorefresh] = useState(true);
    const [showChart, setShowChart] = useState(true);
    const [showMa, setShowMa] = useState(true);
    const [refreshObj, setRefreshObj] = useState([]);
    const [range, setRange] = useState([0, -1]);

    const paramChanges = [];

    useEffect(() => {
        getTrainingChart(runId).then(data => {
            const result = []; // Prealloc

            for (let step in data) {
                const { loss } = data[step];

                result.push({ x: Number(step), y: loss });
            }

            return result;
        }).then(setChart);
    }, [runId, refreshObj]);

    useEffect(() => {
        let key = [0];

        function refresh() {
            if (autorefresh) setRefreshObj([]); // New object triggers re-render
            key[0] = window.setTimeout(() => refresh(), 1000);
        }

        refresh();
        return () => window.clearTimeout(key[0]);
    }, [autorefresh]);

    const ma50 = useMemo(() => {
        if (!maCount || !chart || !chart.length) {
            return [];
        }

        const result = []; // Prealloc
        let accum = [];

        const start = Math.min(chart.length < maCount ? 1 : maCount, 16);
        const stepSize = Math.max((chart.length / 1000) | 0, 1);

        for (let i = 0; i < chart.length; i += stepSize) {
            const l = accum.push(chart[i].y);

            if (l > maCount) {
                accum.shift();
            }

            const avg = accum.reduce((acc, v) => acc + v, 0) / accum.length;

            if (i >= start) {
                result.push({ x: chart[i].x, y: avg });
            }
        }

        return result;
    }, [chart, maCount]);

    const chartData = useMemo(() => {
        const charts = [];

        if (showChart) {
            charts.push({
                "id": "loss",
                "color": "red",
                "data": chart
            });
        }

        if (showMa) {
            charts.push({
                "id": "MA50",
                "color": "blue",
                "data": ma50
            });
        }

        return charts;
    }, [chart, ma50, showChart, showMa]);

    return (
        <div>
            <div className="run-config-tabs">
                <Button className="tab-btn" disabled={active === 'args'} onClick={() => setActive('args')}>Args</Button>
                <Button className="tab-btn" disabled={active === 'history'} onClick={() => setActive('history')}>History</Button>
                <Button className="tab-btn" disabled={active === 'graph'} onClick={() => setActive('graph')}>Loss graph</Button>
            </div>

            <div className="run-config-tab" style={{ display: active === 'args' ? undefined : 'none' }}>
                <ArgsEditor runId={runId} statusRef={statusRef} />
            </div>

            <div className="run-config-tab" style={{ display: active === 'history' ? undefined : 'none' }}>
                <ArgumentHistory runId={runId} statusRef={statusRef} />
            </div>

            <div className="run-config-tab" style={{ display: active === 'graph' ? undefined : 'none' }}>
                <div>
                    <Checkbox checked={autorefresh} onChange={v => setAutorefresh(v.target.checked)}>Auto-refresh</Checkbox>
                    <Checkbox checked={showChart} onChange={v => setShowChart(v.target.checked)}>Chart</Checkbox>
                    <Checkbox checked={showMa} onChange={v => setShowMa(v.target.checked)}>Mov.avg.</Checkbox>
                </div>

                <div style={{ height: 400 }}>
                    <TrainingChart data={chartData} paramChanges={paramChanges} />
                </div>
            </div>
        </div>
    );
}


const Tooltip = ({ slice: { id, points, x, x0 }, axis }) => {
    const singular = points.reduce((acc, v) => v.serieId in acc ? acc : Object.assign(acc, { [v.serieId]: v }), {});

    return <>
        <BasicTooltip id="Step" value={points[1]?.data?.x} />

        {
            Object.values(singular).map(point => <BasicTooltip
                enableChip
                id={point.serieId}
                color={point.serieColor}
                value={point.data.yFormatted}
            />)
        }</>
}

const TrainingChart = ({ data, paramChanges /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 50, bottom: 30, left: 10 }}
        xScale={{ type: 'linear', }}
        yScale={{ type: 'linear' }}
        yFormat=" >-.6f"
        enableSlices="x"
        curve="linear"
        axisTop={null}
        axisLeft={null}
        animate={false}
        axisRight={{
            format: '.6f',
            legendOffset: 0
        }}
        enableGridX={true}
        colors={{ scheme: 'tableau10' }}
        theme={{
            "tooltip": {
                "container": {
                    "color": "black"
                }
            },
            "grid": {
                "line": {
                    "stroke": "#777777"
                }
            },
            "axis": {
                "domain": {
                    "line": {
                        "stroke": "#777777",
                        "strokeWidth": 1
                    }
                },
                // "grid": 6,
                "legend": {
                    "text": {
                        "fontSize": 12,
                        "fill": "#ff0000",
                        "outlineWidth": 0,
                        "outlineColor": "transparent"
                    }
                },
                "ticks": {
                    "text": {
                        "fontSize": 11,
                        "fill": "#c0c0c0",
                        "outlineWidth": 0,
                        "outlineColor": "transparent"
                    }
                }
            },
        }}
        lineWidth={1}
        enablePoints={false}
        crosshairType="cross"
        enableTouchCrosshair={true}
        useMesh={false}
        legends={[]}
        sliceTooltip={Tooltip}
        markers={[
            // {
            //     axis: 'x',
            //     // legend: 'param change',
            //     lineStyle: {
            //         stroke: 'white',
            //         strokeWidth: 2
            //     },
            //     textStyle: { fill: "white" },
            //     value: 'BY'
            // }
        ]}
    // gridXValues={[0, 20, 40, 60, 80, 100, 120]}
    // gridYValues={[0, 500, 1000, 1500, 2000, 2500]}

    />
)

const ArgumentHistory = (props) => {
    const { runId } = props;

    const [changes, setChanges] = useState([]);

    useEffect(() => {
        getArgumentChanges(runId).then(changes => {
            const remapped = changes.map(change => {
                const {
                    "@timestamp": timestamp,
                    modify_args: data,
                } = change;

                return {
                    timestamp,
                    ...data,
                }
            });

            setChanges(remapped);
        })
    }, [runId]);

    return <div className="argument-history">
        {changes.map(change => <ArgumentChange data={change} key={change.timestamp} />)}
    </div>
}


function stringify(value) {
    if (typeof value === 'undefined') {
        return "<nonexistant>";
    } else if (value === null) {
        return "<null>";
    } else if (value === true) {
        return "<true>";
    } else if (value === false) {
        return "<false>";
    } else {
        return value.toString();
    }
}

const ArgumentChange = (props) => {
    const { data: { timestamp, old: oldArgs, "new": newArgs, modified_keys, step = -1 } } = props;

    return <div className="argument-history-item">
        {modified_keys.map(key => {
            const oldVal = stringify(oldArgs[key]);
            const newVal = stringify(newArgs[key]);

            return (
                <div key={key} className="modified">
                    <span className="changed-arg-step">#{step}</span>

                    <span className="changed-arg">{key}</span> changed from <span className="changed-arg">{oldVal}</span> to <span className="changed-arg">{newVal}</span>.

                    <span className="change-timer">
                        <ReactTimeago date={timestamp} />
                    </span>
                </div>
            )
        })}
    </div>
}


const RunCardTitle = (props) => {
    const { images, args, status, setCollapsed, changes, derive } = props;
    const { pinnedParams } = useSettings()[0];
    const [showArgs, setShowArgs] = useState(false);
    const run_id = status ? status.run_id : (images && images.length ? images[0].run_id : null);

    const [paramsModal, contextHolderModal] = Modal.useModal();
    const statusRef = useRef(status);
    statusRef.current = status;

    const openModal = () => paramsModal.info({
        title: "Experiment: " + run_id,
        width: 800,
        maskClosable: true,
        transitionName: null,
        icon: null,
        style: { maxHeight: '80vh' },
        content: <RunConfig runId={run_id} status={status} statusRef={statusRef} />, // </RunConfig><ArgsEditor runId={run_id} />
    });

    const titleStr = args.output_dir.replace('projects/pixels-randomized-coa2/', '').replace("/opt/jupyter", "");

    const [notif, contextHolder] = notification.useNotification();

    const unabortRun = async () => {
        const runArgs = await fetchRunArgs(run_id);
        runArgs.abort = false;
        await putRunArgs(run_id, runArgs);

        notif.success({
            message: 'Run un-aborted',
            description: 'The run will continue training.'
        });
    };

    const abortRun = async () => {
        if (window.confirm('Are you sure you want to abort this run?')) {
            const runArgs = await fetchRunArgs(run_id);
            runArgs.abort = true;
            await putRunArgs(run_id, runArgs);

            notif.success({
                placement: 'bottomRight',
                message: 'Run aborted',
                description: 'The run will be aborted within the next 50 training steps.',
                key: run_id,
                btn: (<Space>
                    <Button type="link" size="small" onClick={unabortRun}>
                        Undo
                    </Button>
                    <Button type="primary" size="small" onClick={() => notif.destroy(run_id)}>
                        Ok
                    </Button>
                </Space>)
            });
        }
    }

    const deriveRun = () => derive({ ...args });

    return (<>
        {contextHolder}
        {contextHolderModal}

        <span className="btn-edit-args" onClick={openModal}>
            <SettingOutlined />
        </span>

        <span className="btn-edit-args" onClick={deriveRun} style={{ marginLeft: 4 }}>
            <CopyOutlined />
        </span>

        <span className="title-text" onClick={() => setCollapsed(v => !v)}>
            {titleStr} {args.nsfw && <FontAwesomeIcon icon={faWarning} />} {status && <> <LoadingOutlined /> {status.current_status.step} {(status.current_status.vmem_kb / 1024 / 1024).toFixed(1)}/{(status.current_status.vmem_kb_rsvd / 1024 / 1024).toFixed(1)} GB</>}
            <span className="num-changes" onClick={e => { e.preventDefault(); e.stopPropagation(); setShowArgs(v => !v) }}>
                <ToolFilled /> {changes.length}
            </span>

            <span className="pinned-params">
                {pinnedParams.map(pp => (
                    <span key={pp} className={"pinned-param " + paramClass(changes, pp)}>
                        {friendlyName(pp)}: {(args[pp] ?? '').toString()}
                    </span>
                ))}
            </span>
        </span>

        {status ?
            <Button danger icon={<DeleteOutlined />} size="small" type="text" onClick={abortRun} />
            :
            <span className='step-status'>Finished</span>
        }

        {showArgs ? <div className="title-params-wrapper">
            <div className="title-params">
                {changes.map(c => (
                    <div key={c.param} className="change-item">
                        <InfoCircleOutlined /> <b>{c.param}</b> set to <pre title={c.to}>{c.to + ''}</pre> (was <pre title={c.from} className="muted">{c.from + ''}</pre>)
                    </div>
                ))}
            </div>
        </div> : null}
    </>);
}




export default TrainingRun;