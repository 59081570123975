import React, { useState } from "react";

import { Col, Row } from "antd/es";

import InferenceCard from "./Inference";
import TrainingRuns from "./TrainingRuns";
import FloatingSettingsEditor from "./FloatingSettingsEditor";

const SettingsContext = React.createContext([]);

export const SettingsProvider = (props) => {
    const [settings, setSettings] = useState({
        imgIdx: 0,
        previewMultiplier: 1,
        maxResults: 8 * 8,
        maxImages: 8,
        hideTime: false,
        stepFilter: null,
        combineImages: false,
        showLossGraph: false,
        paused: false,
        pinnedParams: [],
        descending: true
    });

    return <SettingsContext.Provider value={[settings, setSettings]} {...props} />;
};

export const useSettings = () => {
    return React.useContext(SettingsContext);
};

export const useSetting = (key) => {
    const [settings, setSettings] = React.useContext(SettingsContext);

    const value = settings[key];
    const setter = v => setSettings(s => ({ ...s, [key]: v }));

    return [value, setter];
}

const RecentResults = ({ derive }) => {
    const [expanded, setExpanded] = useState(false);


    return <SettingsProvider>
        <Col xxl={24} xs={24}>
            <InferenceCard />

            <Row gutter={0} style={{ marginBottom: expanded ? 300 : 86 }}>
                <Col xxl={24} xl={24}>
                    <TrainingRuns derive={derive} />
                </Col>
            </Row>
        </Col>

        <FloatingSettingsEditor expanded={expanded} setExpanded={setExpanded} />
    </SettingsProvider>
}

export default RecentResults;
